import React, { Component } from "react"
import Helmet from "react-helmet"
import Layout from "../components/layout"
import { graphql } from "gatsby"

import NewsPreview from "../components/NewsPreview"

export class News extends Component {
  render() {
    const siteTitle = this.props.data.site.siteMetadata.title
    const news = this.props.data.allContentfulNews.edges

    return (
      <div>
        <Helmet title={siteTitle} />
        <Layout>
          <div className="container">
            <div className="col-md-8 offset-md-2">
              <div className="news-container">
                {news.map(({ node }) => {
                  return (
                    <div key={node.id} className="col-md-12">
                      <NewsPreview news={node} />
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </Layout>
      </div>
    )
  }
}

export default News

export const pageQuery = graphql`
  query aboutQueryAndAboutQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
    allContentfulNews {
      edges {
        node {
          id
          title
          slug
          description {
            description
          }
          updatedAt
        }
      }
    }
  }
`
